import { IconButtonV2 } from '@/components/ui/IconButtonV2';
import type { InputProps, MenuButtonProps, StyleProps } from '@chakra-ui/react';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { t } from 'i18next';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { BsCheckAll } from 'react-icons/bs';
import { HiCheck, HiChevronDown, HiX } from 'react-icons/hi';

export type SelectFieldBaseOption = {
  value: any;
  label: string;
  key?: string;
  isDisabled?: boolean;
};

export type SelectFieldBaseProps = {
  label?: ReactNode;
  name?: string;
  helperText?: string;
  children?: ReactNode;
  options?: SelectFieldBaseOption[];
  isRequired?: boolean;
  mt?: StyleProps['mt'];
  isMulti?: boolean;
  defaultValue?: string | number;
  value?: SelectFieldBaseOption['value'];
  onChange: (value: any) => void;
  leftElement?: ReactNode;
  itemDisplayLimit?: number;
} & Omit<InputProps, 'children' | 'onChange' | 'value'>;

const SelectFieldBase = ({
  name = '',
  label,
  helperText,
  options,
  value,
  isRequired,
  onChange,
  isMulti,
  placeholder,
  width,
  w,
  itemDisplayLimit = 2,
  mt,
  leftElement,
  ...inputProps
}: SelectFieldBaseProps) => {
  const handleChange = useCallback(
    (selectedValue: SelectFieldBaseOption['value']) => {
      let newValue;
      if (isMulti) {
        if (value.includes(selectedValue)) {
          newValue = value.filter((item: any) => item !== selectedValue);
        } else {
          newValue = [...value, selectedValue];
        }
      } else {
        newValue = selectedValue;
      }
      onChange(newValue);
    },
    [isMulti, onChange, value],
  );

  const handleReset = useCallback(() => {
    if (isMulti) {
      onChange([]);
    } else {
      onChange(undefined);
    }
  }, [onChange, isMulti]);

  const handleSelectAll = useCallback(() => {
    if (isMulti) {
      onChange(options?.map((option) => option.value));
    }
  }, [isMulti, options, onChange]);

  /* Set default value if not multi */
  useEffect(() => {
    if (!isMulti && !value && options?.length) {
      onChange(options[0].value);
    }
  }, [isMulti, onChange, value, options]);

  const values = useMemo(
    () =>
      options
        ?.filter((option) => {
          if (isMulti) {
            return value?.includes(option.value);
          } else {
            return option.value === value;
          }
        })
        .map((item) => item.label),
    [value, isMulti, options],
  );

  return (
    <FormControl width={width || w} mt={mt} isRequired={isRequired}>
      {label && <FormLabel mb="1">{label}</FormLabel>}
      <Menu closeOnSelect={!isMulti} matchWidth>
        {/* MenuButton and Input used like this to hack the chakra api */}
        <MenuButton
          as={React.forwardRef(function InputWithRef(
            menuButtonProps: MenuButtonProps & {
              onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
            },
            menuButtonRef,
          ) {
            return (
              <InputGroup cursor="pointer" size={inputProps.size} width={width}>
                {leftElement && (
                  <InputLeftElement onClick={menuButtonProps.onClick}>
                    {leftElement}
                  </InputLeftElement>
                )}
                <Input
                  ref={menuButtonRef}
                  aria-expanded={menuButtonProps['aria-expanded']}
                  aria-controls={menuButtonProps['aria-controls']}
                  aria-haspopup={menuButtonProps['aria-haspopup']}
                  id={menuButtonProps.id}
                  onClick={menuButtonProps.onClick}
                  value={
                    !!values?.length && values.length > itemDisplayLimit
                      ? `${values?.length} ${t('common:selected').toLowerCase()}`
                      : values?.join(', ')
                  }
                  cursor="pointer"
                  readOnly={true}
                  name={name}
                  placeholder={placeholder}
                  textOverflow="ellipsis"
                  width={width}
                  _active={{
                    textOverflow: 'ellipsis',
                  }}
                  {...inputProps}
                />
                {values?.length && isMulti ? (
                  <InputRightElement
                    onClick={handleReset}
                    borderRadius="full"
                    _hover={{
                      bg: 'blackAlpha.100',
                    }}
                  >
                    <Icon as={HiX} />
                  </InputRightElement>
                ) : (
                  <InputRightElement onClick={menuButtonProps.onClick}>
                    <Icon as={HiChevronDown} />
                  </InputRightElement>
                )}
              </InputGroup>
            );
          })}
        />
        <Portal>
          <MenuList maxHeight="300px" overflowY="auto">
            {options?.map((option) => {
              const isSelected = isMulti ? value?.includes(option.value) : option.value === value;
              return (
                <MenuItem
                  bg={isSelected ? 'primary.50' : undefined}
                  color={isSelected ? 'primary.600' : undefined}
                  fontWeight={isSelected ? 500 : 500}
                  key={option.value}
                  value={option.value}
                  onClick={() => handleChange(option.value)}
                  isDisabled={option.isDisabled}
                >
                  {isSelected && <Icon as={HiCheck} mr={2} />}
                  {option.label}
                </MenuItem>
              );
            })}
            {isMulti && (
              <Box display="flex" justifyContent="flex-end" px={2} mt={1} gap={1}>
                <IconButtonV2
                  isDisabled={value?.length === options?.length}
                  onClick={handleSelectAll}
                  colorScheme="gray"
                  label={t('common:select_all')}
                  IconComponent={BsCheckAll}
                />
                <IconButtonV2
                  isDisabled={!value?.length}
                  onClick={handleReset}
                  IconComponent={HiX}
                  colorScheme="red"
                  label={t('common:unselect_all')}
                />
              </Box>
            )}
          </MenuList>
        </Portal>
      </Menu>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectFieldBase;
