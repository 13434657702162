import { Box, Text } from '@chakra-ui/react';
import type { MayHaveLabel, PieTooltipProps } from '@nivo/pie';
import type { FC } from 'react';

export const PieChartTooltip: FC<PieTooltipProps<MayHaveLabel>> = ({ datum }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      boxShadow="md"
      px={2}
      borderRadius="md"
    >
      <Box
        height="12px"
        width="12px"
        borderRadius="12px"
        marginRight="4px"
        backgroundColor={datum.color}
      />
      <Text fontSize="sm">
        {datum.label} <b>{datum.value}</b>
      </Text>
    </Box>
  );
};
