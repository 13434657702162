import type { FC } from 'react';
import dynamic from 'next/dynamic';
const ResponsiveLine = dynamic(() => import('@nivo/line').then((module) => module.ResponsiveLine), {
  ssr: false,
});
import type { Datum, SliceTooltipProps } from '@nivo/line';

export interface ILineChartProps {
  data: ChartData;
  defaultColor?: string[];
  tooltip?: FC<SliceTooltipProps>;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

export type ChartData = {
  label: string;
  id: string;
  data: Datum[];
  color?: string;
}[];

export const LineChart: FC<ILineChartProps> = ({ data, tooltip, defaultColor, margin }) => {
  return (
    <ResponsiveLine
      data={data}
      margin={margin}
      sliceTooltip={tooltip}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      enableArea={true}
      curve="monotoneX"
      yFormat={(value) => `${value} €`}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridY={false}
      enableGridX={false}
      colors={defaultColor ?? data[0].color}
      enablePoints={false}
      enableSlices="x"
      crosshairType="bottom"
      useMesh={true}
      animate={true}
      motionConfig="default"
    />
  );
};
