import type { SubscriptionDto } from '@/generated/typing';
import { SubscriptionStatus } from '@/generated/typing';

const statusToColor = {
  active: 'green',
  pending: 'orange',
  voided: 'red',
  canceled: 'gray',
  refunded: 'purple',
  trial: 'blue',
  failed: 'red',
  expired: 'beige',
};

export const getColorStatus = (subscription?: Pick<SubscriptionDto, 'status' | 'isInTrial'>) => {
  if (!subscription) {
    return 'gray';
  }
  if (subscription.status === SubscriptionStatus.Active && subscription.isInTrial) {
    return statusToColor.trial;
  }
  return statusToColor[subscription.status] || 'gray';
};
