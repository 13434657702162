import React from 'react';
import api from '@/core/client';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { subMonths } from 'date-fns';
import SimpleCounter from './StatsCounter';
import Link from 'next/link';
import type { StoreDto } from '@/generated/typing';
import Title from '../ui/Title';
import { HiMiniUserGroup, HiUserGroup } from 'react-icons/hi2';
import Panel from '@/components/ui/Panel';
import { Box, Icon } from '@chakra-ui/react';

export interface ICustomersCountCardProps {
  startDate?: string;
  endDate?: string;
  activeStore?: StoreDto;
  selectedStoreIds?: string[];
}

const CustomersCountCard = ({
  startDate,
  endDate,
  activeStore,
  selectedStoreIds = [],
}: ICustomersCountCardProps) => {
  const { t } = useTranslation(['common', 'dashboard']);

  const { data: customersCount } = useQuery({
    queryKey: [
      'stores',
      activeStore?.id ?? '',
      'customersCount',
      { startDate, endDate, selectedStoreIds },
    ],
    queryFn: () =>
      api.statistics.getCountCustomersByStore(activeStore?.id ?? '', {
        from: startDate,
        to: endDate,
        selectedStoreIds: selectedStoreIds || [],
      }),
    enabled: !!activeStore?.id && !!selectedStoreIds,
    keepPreviousData: true,
  });
  const { data: customersCountlastMonth } = useQuery({
    queryKey: [
      'stores',
      activeStore?.id ?? '',
      'customersCountlastMonth',
      { startDate, endDate, selectedStoreIds },
    ],
    queryFn: () =>
      api.statistics.getCountCustomersByStore(activeStore?.id ?? '', {
        from: subMonths(startDate ? new Date(startDate) : new Date(), 1).toISOString(),
        to: subMonths(endDate ? new Date(endDate) : new Date(), 1).toISOString(),
        selectedStoreIds,
      }),
    enabled: !!activeStore?.id && !!selectedStoreIds,
    keepPreviousData: true,
  });

  if (!activeStore) return null;

  return (
    <Panel
      filled
      overflow="hidden"
      h="100%"
      bg="primary.50"
      color="primary.600"
      position="relative"
    >
      <Title
        IconComponent={HiMiniUserGroup}
        title={t('dashboard:insured_nb')}
        border="none"
        color="primary.600"
      />
      <Link href={`/stores/${activeStore?.id}/customers`}>
        <SimpleCounter countTo={customersCount ?? 0} countFrom={customersCountlastMonth ?? 0} />
      </Link>
      <Box position="absolute" bottom="-40px" right="2px">
        <Icon as={HiUserGroup} color="primary.100" fontSize="140px" />
      </Box>
    </Panel>
  );
};

export default CustomersCountCard;
