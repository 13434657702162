import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import Title from '@/components/ui/Title';

const Panel = ({
  children,
  title,
  filled,
  bordered,
  ...boxProps
}: { title?: string; bordered?: boolean; filled?: boolean } & BoxProps) => {
  return (
    <Box
      alignItems="stretch"
      flexDirection="column"
      w="100%"
      bg={filled ? 'gray.50' : undefined}
      borderWidth={bordered && !filled ? '1px' : undefined}
      borderColor="gray.200"
      borderRadius="md"
      p={bordered || filled ? 6 : undefined}
      {...boxProps}
    >
      {title && <Title noBorder={!!bordered}>{title}</Title>}
      {children}
    </Box>
  );
};

export default Panel;
