import type { As, StyleProps } from '@chakra-ui/react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import React from 'react';

interface SectionTitleProps {
  title?: ReactNode;
  caption?: ReactNode;
  isInline?: boolean;
  IconComponent?: As;
  rightContent?: ReactNode;
  children?: ReactNode;
  noBorder?: boolean;
}

const Title = ({
  title,
  caption,
  fontSize = 'lg',
  isInline,
  IconComponent,
  rightContent,
  noBorder,
  children,
  ...styleProps
}: SectionTitleProps & StyleProps) => (
  <Stack
    alignItems="baseline"
    spacing={isInline ? 2 : 0}
    direction={isInline ? 'row' : 'column'}
    ml={0}
    color="text1"
    mb={3}
    w="100%"
    pb={2}
    {...styleProps}
  >
    <Flex justify="space-between" align="center" w="100%" flexWrap="wrap" gap={2} pb={0}>
      <Text fontWeight={600} fontSize={fontSize}>
        {title || children}
      </Text>
      {rightContent && <Box my={-2}>{rightContent}</Box>}
    </Flex>

    {caption && (
      <Box fontSize="sm" color="gray.600">
        {caption}
      </Box>
    )}
  </Stack>
);

export default Title;
