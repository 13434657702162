import api from '@/core/client';
import type { StoreDto } from '@/generated/typing';
import { useToken } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { HiMiniChartPie } from 'react-icons/hi2';
import type { IPieData } from '../ui/charts/pie-chart/PieChart';
import { PieChart } from '../ui/charts/pie-chart/PieChart';
import Title from '../ui/Title';
import Panel from '@/components/ui/Panel';

export interface IProductsSalesCardProps {
  startDate?: string;
  endDate?: string;
  activeStore?: StoreDto;
  selectedStoreIds?: string[];
}

const ProductsSalesCard = ({
  startDate,
  endDate,
  activeStore,
  selectedStoreIds,
}: IProductsSalesCardProps) => {
  const { t } = useTranslation(['common', 'dashboard']);
  const colors = useToken('colors', [
    'primary.400',
    'primary.300',
    'primary.200',
    'primary.100',
    'primary.800',
    'primary.700',
    'primary.600',
    'primary.500',
  ]);

  const { data: storeSalesByProduct } = useQuery({
    queryKey: [
      'stores',
      activeStore?.id,
      'storeSalesByProduct',
      { startDate, endDate, selectedStoreIds },
    ],
    queryFn: () =>
      api.statistics.getStoreSalesByProducts(activeStore?.id ?? '', {
        from: startDate,
        to: endDate,
        selectedStoreIds: selectedStoreIds ?? [],
      }),
    enabled: !!activeStore?.id,
    keepPreviousData: true,
  });

  const data = (storeSalesByProduct || [])
    ?.sort((a, b) => a.sum - b.sum)
    .map(
      (storeProductSales, i) =>
        ({
          id: storeProductSales.product.id,
          value: storeProductSales.sum,
          label: storeProductSales.product.name,
          color: colors[i % colors.length],
        } as IPieData),
    );

  return (
    <Panel filled>
      <Title title={t('dashboard:revenue_per_product')} IconComponent={HiMiniChartPie} />
      <PieChart data={data} height="200px" />
    </Panel>
  );
};

export default ProductsSalesCard;
