import { Card } from '@chakra-ui/react';

export const SlicedTooltip = ({ slice }: any) => {
  return (
    <Card p={2}>
      <div>
        <strong>
          {new Date(slice.points[0].data.xFormatted).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </strong>
      </div>
      {slice.points.map((point: any) => (
        <p key={point.id}>{point.data.yFormatted}</p>
      ))}
    </Card>
  );
};
