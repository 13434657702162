import { Flex, Text } from '@chakra-ui/react';
import { format, startOfDay, subDays, subMonths } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateFilterModal from '@/components/table/DateFilterModal';
import SelectFieldBase from '@/components/forms/formFields/SelectFieldBase';

export enum SelectableDates {
  TODAY = 'today',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_3_MONTHS = 'last3Months',
  LAST_6_MONTHS = 'last6Months',
  LAST_12_MONTHS = 'last12Months',
  ALL = 'all',
  CUSTOM = 'custom',
}

export interface IDateSelectorProps {
  startDate?: string | Date;
  endDate?: string | Date;
  onChange: (startDate?: string, endDate?: string) => void;
  defaultSelected?: SelectableDates;
}

export const DateSelector = ({
  onChange,
  startDate,
  endDate,
  defaultSelected = SelectableDates.LAST_7_DAYS,
}: IDateSelectorProps) => {
  const { t } = useTranslation(['common']);
  const [dateFilterIsOpen, setDateFilterIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(defaultSelected);

  const [parsedStartDate, parsedEndDate] = useMemo(() => {
    return [
      typeof startDate === 'string' ? new Date(startDate) : startDate,
      typeof endDate === 'string' ? new Date(endDate) : endDate,
    ];
  }, [startDate, endDate]);

  const dates: Record<SelectableDates, [string?, string?] | undefined> = useMemo(() => {
    const now = new Date();
    const nowString = now.toISOString();
    return {
      today: [startOfDay(now).toISOString(), nowString],
      last7Days: [subDays(now, 7).toISOString(), nowString],
      last30Days: [subDays(now, 30).toISOString(), nowString],
      last3Months: [subMonths(now, 3).toISOString(), nowString],
      last6Months: [subMonths(now, 6).toISOString(), nowString],
      last12Months: [subMonths(now, 12).toISOString(), nowString],
      custom: [subDays(now, 30).toISOString(), nowString],
      all: [undefined, undefined],
    };
  }, []);

  const options = [
    {
      label: t('common:all'),
      value: 'all',
    },
    {
      label: t('common:today'),
      value: 'today',
    },
    {
      label: t('common:last_7_days'),
      value: 'last7Days',
    },
    {
      label: t('common:last_30_days'),
      value: 'last30Days',
    },
    {
      label: t('common:last_3_months'),
      value: 'last3Months',
    },
    {
      label: t('common:last_6_months'),
      value: 'last6Months',
    },
    {
      label: t('common:last_12_months'),
      value: 'last12Months',
    },
    {
      label: t('common:custom'),
      value: 'custom',
    },
  ];

  const handleChange = (value: SelectableDates) => {
    if (value === SelectableDates.CUSTOM) {
      setDateFilterIsOpen(true);
    } else if (value in dates) {
      const startDate = dates[value]?.[0];
      const endDate = dates[value]?.[1];
      onChange(startDate, endDate);
    }
    setSelectedDate(value);
  };

  return (
    <>
      <Flex alignItems="center" gap={2} flexWrap="wrap">
        <Text
          display="block"
          size="md"
          as="em"
          color="text3"
          fontSize="sm"
          letterSpacing="sm"
          flexShrink={0}
        >
          {parsedStartDate && format(parsedStartDate, 'dd MMM yyyy')}{' '}
          {(parsedStartDate ?? parsedEndDate) && `>`}{' '}
          {parsedEndDate && format(parsedEndDate, 'dd MMM yyyy')}
        </Text>
        <SelectFieldBase
          options={options}
          onChange={handleChange}
          value={selectedDate}
          variant="plain"
          size="sm"
          width="160px"
        />
      </Flex>
      <DateFilterModal
        isOpen={dateFilterIsOpen}
        onSubmit={(from, to) => {
          setDateFilterIsOpen(false);
          onChange(from.toISOString(), to.toISOString());
        }}
        onClose={() => setDateFilterIsOpen(false)}
        from={parsedStartDate}
        to={parsedEndDate}
      />
    </>
  );
};
