import { useUserContext } from '@/contexts/user-context';
import { Box, Center, Spinner } from '@chakra-ui/react';
import { useRouter } from '@/hooks/useRouter';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { useDealerContext } from '@/contexts/dealer-context';
import { useProviderAccountContext } from '@/contexts/provider-account-context';
import { useSession } from 'next-auth/react';
import type { Session } from '@/types/next-auth';

const StoresGuard = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { data: session } = useSession() as { data: Session | null };
  const { loading: userLoading, stores, isAdmin } = useUserContext();
  const { isDealerActor, loading: dealerLoading } = useDealerContext();
  const { providerAccount, isProviderAccountActor, providerAccountLoading } =
    useProviderAccountContext();

  const userHasAStore = useMemo(() => stores.length > 0, [stores]);
  const userHasAValidatedStore = useMemo(
    () => stores.some((store) => store.validationStatus === 'Validated'),
    [stores],
  );
  const isCreateStoreRouteActive = useMemo(
    () => router.isLocatedIn('/stores/create-first'),
    [router],
  );
  const isAwaitingForValidationRouteActive = useMemo(
    () => router.isLocatedIn('/stores/awaiting-store-validation'),
    [router],
  );
  const isInvitationsRouteActive = useMemo(() => router.isLocatedIn('/invitations'), [router]);

  useEffect(() => {
    if (!session || userLoading || isInvitationsRouteActive) {
      return;
    }

    if (isProviderAccountActor && providerAccount?.providerId) {
      router.push(`/admin/providers/${providerAccount.providerId}/subscriptions`);
      return;
    }

    if (isDealerActor) {
      if (!userHasAStore && !isCreateStoreRouteActive) {
        router.push('/stores/create-first');
        return;
      }

      if (userHasAStore && !userHasAValidatedStore && !isAwaitingForValidationRouteActive) {
        router.push('/stores/awaiting-store-validation');
        return;
      }
    }

    if (
      (isCreateStoreRouteActive && (isAdmin || userHasAStore)) ||
      (isAwaitingForValidationRouteActive && (isAdmin || userHasAValidatedStore))
    ) {
      router.push('/');
    }
  }, [
    dealerLoading,
    isAdmin,
    isAwaitingForValidationRouteActive,
    isCreateStoreRouteActive,
    isDealerActor,
    isInvitationsRouteActive,
    isProviderAccountActor,
    providerAccount?.providerId,
    providerAccountLoading,
    router,
    session,
    userHasAStore,
    userHasAValidatedStore,
    userLoading,
  ]);

  if (
    userLoading ||
    (!isAdmin &&
      !isInvitationsRouteActive &&
      ((!userHasAStore && !isCreateStoreRouteActive) ||
        (userHasAStore && !userHasAValidatedStore && !isAwaitingForValidationRouteActive)))
  ) {
    return (
      <Center bgColor="gray.100" w="100vw" h="100vh">
        <Spinner color="gray.600" size="xl" />
      </Center>
    );
  }
  return <Box>{children}</Box>;
};

export default StoresGuard;
